@import "https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";
@font-face {
  font-family: codicon;
  src: url("codicon.0b1acdcc.ttf") format("truetype");
}

.codicon[class*="codicon-"] {
  text-rendering: auto;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  user-select: none;
  font: 16px / 1 codicon;
  text-decoration: none;
  display: block;
}

.codicon-add:before, .codicon-plus:before, .codicon-gist-new:before, .codicon-repo-create:before {
  content: "";
}

.codicon-lightbulb:before, .codicon-light-bulb:before {
  content: "";
}

.codicon-repo:before, .codicon-repo-delete:before {
  content: "";
}

.codicon-gist-fork:before, .codicon-repo-forked:before {
  content: "";
}

.codicon-git-pull-request:before, .codicon-git-pull-request-abandoned:before {
  content: "";
}

.codicon-record-keys:before, .codicon-keyboard:before {
  content: "";
}

.codicon-tag:before, .codicon-tag-add:before, .codicon-tag-remove:before {
  content: "";
}

.codicon-person:before, .codicon-person-follow:before, .codicon-person-outline:before, .codicon-person-filled:before {
  content: "";
}

.codicon-git-branch:before, .codicon-git-branch-create:before, .codicon-git-branch-delete:before, .codicon-source-control:before {
  content: "";
}

.codicon-mirror:before, .codicon-mirror-public:before {
  content: "";
}

.codicon-star:before, .codicon-star-add:before, .codicon-star-delete:before, .codicon-star-empty:before {
  content: "";
}

.codicon-comment:before, .codicon-comment-add:before {
  content: "";
}

.codicon-alert:before, .codicon-warning:before {
  content: "";
}

.codicon-search:before, .codicon-search-save:before {
  content: "";
}

.codicon-log-out:before, .codicon-sign-out:before {
  content: "";
}

.codicon-log-in:before, .codicon-sign-in:before {
  content: "";
}

.codicon-eye:before, .codicon-eye-unwatch:before, .codicon-eye-watch:before {
  content: "";
}

.codicon-circle-filled:before, .codicon-primitive-dot:before, .codicon-close-dirty:before, .codicon-debug-breakpoint:before, .codicon-debug-breakpoint-disabled:before, .codicon-debug-hint:before {
  content: "";
}

.codicon-primitive-square:before {
  content: "";
}

.codicon-edit:before, .codicon-pencil:before {
  content: "";
}

.codicon-info:before, .codicon-issue-opened:before {
  content: "";
}

.codicon-gist-private:before, .codicon-git-fork-private:before, .codicon-lock:before, .codicon-mirror-private:before {
  content: "";
}

.codicon-close:before, .codicon-remove-close:before, .codicon-x:before {
  content: "";
}

.codicon-repo-sync:before, .codicon-sync:before {
  content: "";
}

.codicon-clone:before, .codicon-desktop-download:before {
  content: "";
}

.codicon-beaker:before, .codicon-microscope:before {
  content: "";
}

.codicon-vm:before, .codicon-device-desktop:before {
  content: "";
}

.codicon-file:before, .codicon-file-text:before {
  content: "";
}

.codicon-more:before, .codicon-ellipsis:before, .codicon-kebab-horizontal:before {
  content: "";
}

.codicon-mail-reply:before, .codicon-reply:before {
  content: "";
}

.codicon-organization:before, .codicon-organization-filled:before, .codicon-organization-outline:before {
  content: "";
}

.codicon-new-file:before, .codicon-file-add:before {
  content: "";
}

.codicon-new-folder:before, .codicon-file-directory-create:before {
  content: "";
}

.codicon-trash:before, .codicon-trashcan:before {
  content: "";
}

.codicon-history:before, .codicon-clock:before {
  content: "";
}

.codicon-folder:before, .codicon-file-directory:before, .codicon-symbol-folder:before {
  content: "";
}

.codicon-logo-github:before, .codicon-mark-github:before, .codicon-github:before {
  content: "";
}

.codicon-terminal:before, .codicon-console:before, .codicon-repl:before {
  content: "";
}

.codicon-zap:before, .codicon-symbol-event:before {
  content: "";
}

.codicon-error:before, .codicon-stop:before {
  content: "";
}

.codicon-variable:before, .codicon-symbol-variable:before {
  content: "";
}

.codicon-array:before, .codicon-symbol-array:before {
  content: "";
}

.codicon-symbol-module:before, .codicon-symbol-package:before, .codicon-symbol-namespace:before, .codicon-symbol-object:before {
  content: "";
}

.codicon-symbol-method:before, .codicon-symbol-function:before, .codicon-symbol-constructor:before {
  content: "";
}

.codicon-symbol-boolean:before, .codicon-symbol-null:before {
  content: "";
}

.codicon-symbol-numeric:before, .codicon-symbol-number:before {
  content: "";
}

.codicon-symbol-structure:before, .codicon-symbol-struct:before {
  content: "";
}

.codicon-symbol-parameter:before, .codicon-symbol-type-parameter:before {
  content: "";
}

.codicon-symbol-key:before, .codicon-symbol-text:before {
  content: "";
}

.codicon-symbol-reference:before, .codicon-go-to-file:before {
  content: "";
}

.codicon-symbol-enum:before, .codicon-symbol-value:before {
  content: "";
}

.codicon-symbol-ruler:before, .codicon-symbol-unit:before {
  content: "";
}

.codicon-activate-breakpoints:before {
  content: "";
}

.codicon-archive:before {
  content: "";
}

.codicon-arrow-both:before {
  content: "";
}

.codicon-arrow-down:before {
  content: "";
}

.codicon-arrow-left:before {
  content: "";
}

.codicon-arrow-right:before {
  content: "";
}

.codicon-arrow-small-down:before {
  content: "";
}

.codicon-arrow-small-left:before {
  content: "";
}

.codicon-arrow-small-right:before {
  content: "";
}

.codicon-arrow-small-up:before {
  content: "";
}

.codicon-arrow-up:before {
  content: "";
}

.codicon-bell:before {
  content: "";
}

.codicon-bold:before {
  content: "";
}

.codicon-book:before {
  content: "";
}

.codicon-bookmark:before {
  content: "";
}

.codicon-debug-breakpoint-conditional-unverified:before {
  content: "";
}

.codicon-debug-breakpoint-conditional:before, .codicon-debug-breakpoint-conditional-disabled:before {
  content: "";
}

.codicon-debug-breakpoint-data-unverified:before {
  content: "";
}

.codicon-debug-breakpoint-data:before, .codicon-debug-breakpoint-data-disabled:before {
  content: "";
}

.codicon-debug-breakpoint-log-unverified:before {
  content: "";
}

.codicon-debug-breakpoint-log:before, .codicon-debug-breakpoint-log-disabled:before {
  content: "";
}

.codicon-briefcase:before {
  content: "";
}

.codicon-broadcast:before {
  content: "";
}

.codicon-browser:before {
  content: "";
}

.codicon-bug:before {
  content: "";
}

.codicon-calendar:before {
  content: "";
}

.codicon-case-sensitive:before {
  content: "";
}

.codicon-check:before {
  content: "";
}

.codicon-checklist:before {
  content: "";
}

.codicon-chevron-down:before {
  content: "";
}

.codicon-chevron-left:before {
  content: "";
}

.codicon-chevron-right:before {
  content: "";
}

.codicon-chevron-up:before {
  content: "";
}

.codicon-chrome-close:before {
  content: "";
}

.codicon-chrome-maximize:before {
  content: "";
}

.codicon-chrome-minimize:before {
  content: "";
}

.codicon-chrome-restore:before {
  content: "";
}

.codicon-circle-outline:before, .codicon-debug-breakpoint-unverified:before {
  content: "";
}

.codicon-circle-slash:before {
  content: "";
}

.codicon-circuit-board:before {
  content: "";
}

.codicon-clear-all:before {
  content: "";
}

.codicon-clippy:before {
  content: "";
}

.codicon-close-all:before {
  content: "";
}

.codicon-cloud-download:before {
  content: "";
}

.codicon-cloud-upload:before {
  content: "";
}

.codicon-code:before {
  content: "";
}

.codicon-collapse-all:before {
  content: "";
}

.codicon-color-mode:before {
  content: "";
}

.codicon-comment-discussion:before {
  content: "";
}

.codicon-credit-card:before {
  content: "";
}

.codicon-dash:before {
  content: "";
}

.codicon-dashboard:before {
  content: "";
}

.codicon-database:before {
  content: "";
}

.codicon-debug-continue:before {
  content: "";
}

.codicon-debug-disconnect:before {
  content: "";
}

.codicon-debug-pause:before {
  content: "";
}

.codicon-debug-restart:before {
  content: "";
}

.codicon-debug-start:before {
  content: "";
}

.codicon-debug-step-into:before {
  content: "";
}

.codicon-debug-step-out:before {
  content: "";
}

.codicon-debug-step-over:before {
  content: "";
}

.codicon-debug-stop:before {
  content: "";
}

.codicon-debug:before {
  content: "";
}

.codicon-device-camera-video:before {
  content: "";
}

.codicon-device-camera:before {
  content: "";
}

.codicon-device-mobile:before {
  content: "";
}

.codicon-diff-added:before {
  content: "";
}

.codicon-diff-ignored:before {
  content: "";
}

.codicon-diff-modified:before {
  content: "";
}

.codicon-diff-removed:before {
  content: "";
}

.codicon-diff-renamed:before {
  content: "";
}

.codicon-diff:before {
  content: "";
}

.codicon-discard:before {
  content: "";
}

.codicon-editor-layout:before {
  content: "";
}

.codicon-empty-window:before {
  content: "";
}

.codicon-exclude:before {
  content: "";
}

.codicon-extensions:before {
  content: "";
}

.codicon-eye-closed:before {
  content: "";
}

.codicon-file-binary:before {
  content: "";
}

.codicon-file-code:before {
  content: "";
}

.codicon-file-media:before {
  content: "";
}

.codicon-file-pdf:before {
  content: "";
}

.codicon-file-submodule:before {
  content: "";
}

.codicon-file-symlink-directory:before {
  content: "";
}

.codicon-file-symlink-file:before {
  content: "";
}

.codicon-file-zip:before {
  content: "";
}

.codicon-files:before {
  content: "";
}

.codicon-filter:before {
  content: "";
}

.codicon-flame:before {
  content: "";
}

.codicon-fold-down:before {
  content: "";
}

.codicon-fold-up:before {
  content: "";
}

.codicon-fold:before {
  content: "";
}

.codicon-folder-active:before {
  content: "";
}

.codicon-folder-opened:before {
  content: "";
}

.codicon-gear:before {
  content: "";
}

.codicon-gift:before {
  content: "";
}

.codicon-gist-secret:before {
  content: "";
}

.codicon-gist:before {
  content: "";
}

.codicon-git-commit:before {
  content: "";
}

.codicon-git-compare:before, .codicon-compare-changes:before {
  content: "";
}

.codicon-git-merge:before {
  content: "";
}

.codicon-github-action:before {
  content: "";
}

.codicon-github-alt:before {
  content: "";
}

.codicon-globe:before {
  content: "";
}

.codicon-grabber:before {
  content: "";
}

.codicon-graph:before {
  content: "";
}

.codicon-gripper:before {
  content: "";
}

.codicon-heart:before {
  content: "";
}

.codicon-home:before {
  content: "";
}

.codicon-horizontal-rule:before {
  content: "";
}

.codicon-hubot:before {
  content: "";
}

.codicon-inbox:before {
  content: "";
}

.codicon-issue-reopened:before {
  content: "";
}

.codicon-issues:before {
  content: "";
}

.codicon-italic:before {
  content: "";
}

.codicon-jersey:before {
  content: "";
}

.codicon-json:before {
  content: "";
}

.codicon-kebab-vertical:before {
  content: "";
}

.codicon-key:before {
  content: "";
}

.codicon-law:before {
  content: "";
}

.codicon-lightbulb-autofix:before {
  content: "";
}

.codicon-link-external:before {
  content: "";
}

.codicon-link:before {
  content: "";
}

.codicon-list-ordered:before {
  content: "";
}

.codicon-list-unordered:before {
  content: "";
}

.codicon-live-share:before {
  content: "";
}

.codicon-loading:before {
  content: "";
}

.codicon-location:before {
  content: "";
}

.codicon-mail-read:before {
  content: "";
}

.codicon-mail:before {
  content: "";
}

.codicon-markdown:before {
  content: "";
}

.codicon-megaphone:before {
  content: "";
}

.codicon-mention:before {
  content: "";
}

.codicon-milestone:before {
  content: "";
}

.codicon-mortar-board:before {
  content: "";
}

.codicon-move:before {
  content: "";
}

.codicon-multiple-windows:before {
  content: "";
}

.codicon-mute:before {
  content: "";
}

.codicon-no-newline:before {
  content: "";
}

.codicon-note:before {
  content: "";
}

.codicon-octoface:before {
  content: "";
}

.codicon-open-preview:before {
  content: "";
}

.codicon-package:before {
  content: "";
}

.codicon-paintcan:before {
  content: "";
}

.codicon-pin:before {
  content: "";
}

.codicon-play:before, .codicon-run:before {
  content: "";
}

.codicon-plug:before {
  content: "";
}

.codicon-preserve-case:before {
  content: "";
}

.codicon-preview:before {
  content: "";
}

.codicon-project:before {
  content: "";
}

.codicon-pulse:before {
  content: "";
}

.codicon-question:before {
  content: "";
}

.codicon-quote:before {
  content: "";
}

.codicon-radio-tower:before {
  content: "";
}

.codicon-reactions:before {
  content: "";
}

.codicon-references:before {
  content: "";
}

.codicon-refresh:before {
  content: "";
}

.codicon-regex:before {
  content: "";
}

.codicon-remote-explorer:before {
  content: "";
}

.codicon-remote:before {
  content: "";
}

.codicon-remove:before {
  content: "";
}

.codicon-replace-all:before {
  content: "";
}

.codicon-replace:before {
  content: "";
}

.codicon-repo-clone:before {
  content: "";
}

.codicon-repo-force-push:before {
  content: "";
}

.codicon-repo-pull:before {
  content: "";
}

.codicon-repo-push:before {
  content: "";
}

.codicon-report:before {
  content: "";
}

.codicon-request-changes:before {
  content: "";
}

.codicon-rocket:before {
  content: "";
}

.codicon-root-folder-opened:before {
  content: "";
}

.codicon-root-folder:before {
  content: "";
}

.codicon-rss:before {
  content: "";
}

.codicon-ruby:before {
  content: "";
}

.codicon-save-all:before {
  content: "";
}

.codicon-save-as:before {
  content: "";
}

.codicon-save:before {
  content: "";
}

.codicon-screen-full:before {
  content: "";
}

.codicon-screen-normal:before {
  content: "";
}

.codicon-search-stop:before {
  content: "";
}

.codicon-server:before {
  content: "";
}

.codicon-settings-gear:before {
  content: "";
}

.codicon-settings:before {
  content: "";
}

.codicon-shield:before {
  content: "";
}

.codicon-smiley:before {
  content: "";
}

.codicon-sort-precedence:before {
  content: "";
}

.codicon-split-horizontal:before {
  content: "";
}

.codicon-split-vertical:before {
  content: "";
}

.codicon-squirrel:before {
  content: "";
}

.codicon-star-full:before {
  content: "";
}

.codicon-star-half:before {
  content: "";
}

.codicon-symbol-class:before {
  content: "";
}

.codicon-symbol-color:before {
  content: "";
}

.codicon-symbol-constant:before {
  content: "";
}

.codicon-symbol-enum-member:before {
  content: "";
}

.codicon-symbol-field:before {
  content: "";
}

.codicon-symbol-file:before {
  content: "";
}

.codicon-symbol-interface:before {
  content: "";
}

.codicon-symbol-keyword:before {
  content: "";
}

.codicon-symbol-misc:before {
  content: "";
}

.codicon-symbol-operator:before {
  content: "";
}

.codicon-symbol-property:before, .codicon-wrench:before, .codicon-wrench-subaction:before {
  content: "";
}

.codicon-symbol-snippet:before {
  content: "";
}

.codicon-tasklist:before {
  content: "";
}

.codicon-telescope:before {
  content: "";
}

.codicon-text-size:before {
  content: "";
}

.codicon-three-bars:before {
  content: "";
}

.codicon-thumbsdown:before {
  content: "";
}

.codicon-thumbsup:before {
  content: "";
}

.codicon-tools:before {
  content: "";
}

.codicon-triangle-down:before {
  content: "";
}

.codicon-triangle-left:before {
  content: "";
}

.codicon-triangle-right:before {
  content: "";
}

.codicon-triangle-up:before {
  content: "";
}

.codicon-twitter:before {
  content: "";
}

.codicon-unfold:before {
  content: "";
}

.codicon-unlock:before {
  content: "";
}

.codicon-unmute:before {
  content: "";
}

.codicon-unverified:before {
  content: "";
}

.codicon-verified:before {
  content: "";
}

.codicon-versions:before {
  content: "";
}

.codicon-vm-active:before {
  content: "";
}

.codicon-vm-outline:before {
  content: "";
}

.codicon-vm-running:before {
  content: "";
}

.codicon-watch:before {
  content: "";
}

.codicon-whitespace:before {
  content: "";
}

.codicon-whole-word:before {
  content: "";
}

.codicon-window:before {
  content: "";
}

.codicon-word-wrap:before {
  content: "";
}

.codicon-zoom-in:before {
  content: "";
}

.codicon-zoom-out:before {
  content: "";
}

.codicon-list-filter:before {
  content: "";
}

.codicon-list-flat:before {
  content: "";
}

.codicon-list-selection:before, .codicon-selection:before {
  content: "";
}

.codicon-list-tree:before {
  content: "";
}

.codicon-debug-breakpoint-function-unverified:before {
  content: "";
}

.codicon-debug-breakpoint-function:before, .codicon-debug-breakpoint-function-disabled:before {
  content: "";
}

.codicon-debug-stackframe-active:before {
  content: "";
}

.codicon-debug-stackframe-dot:before {
  content: "";
}

.codicon-debug-stackframe:before, .codicon-debug-stackframe-focused:before {
  content: "";
}

.codicon-debug-breakpoint-unsupported:before {
  content: "";
}

.codicon-symbol-string:before {
  content: "";
}

.codicon-debug-reverse-continue:before {
  content: "";
}

.codicon-debug-step-back:before {
  content: "";
}

.codicon-debug-restart-frame:before {
  content: "";
}

.codicon-debug-alt:before {
  content: "";
}

.codicon-call-incoming:before {
  content: "";
}

.codicon-call-outgoing:before {
  content: "";
}

.codicon-menu:before {
  content: "";
}

.codicon-expand-all:before {
  content: "";
}

.codicon-feedback:before {
  content: "";
}

.codicon-group-by-ref-type:before {
  content: "";
}

.codicon-ungroup-by-ref-type:before {
  content: "";
}

.codicon-account:before {
  content: "";
}

.codicon-bell-dot:before {
  content: "";
}

.codicon-debug-console:before {
  content: "";
}

.codicon-library:before {
  content: "";
}

.codicon-output:before {
  content: "";
}

.codicon-run-all:before {
  content: "";
}

.codicon-sync-ignored:before {
  content: "";
}

.codicon-pinned:before {
  content: "";
}

.codicon-github-inverted:before {
  content: "";
}

.codicon-server-process:before {
  content: "";
}

.codicon-server-environment:before {
  content: "";
}

.codicon-pass:before, .codicon-issue-closed:before {
  content: "";
}

.codicon-stop-circle:before {
  content: "";
}

.codicon-play-circle:before {
  content: "";
}

.codicon-record:before {
  content: "";
}

.codicon-debug-alt-small:before {
  content: "";
}

.codicon-vm-connect:before {
  content: "";
}

.codicon-cloud:before {
  content: "";
}

.codicon-merge:before {
  content: "";
}

.codicon-export:before {
  content: "";
}

.codicon-graph-left:before {
  content: "";
}

.codicon-magnet:before {
  content: "";
}

.codicon-notebook:before {
  content: "";
}

.codicon-redo:before {
  content: "";
}

.codicon-check-all:before {
  content: "";
}

.codicon-pinned-dirty:before {
  content: "";
}

.codicon-pass-filled:before {
  content: "";
}

.codicon-circle-large-filled:before {
  content: "";
}

.codicon-circle-large-outline:before {
  content: "";
}

.codicon-combine:before, .codicon-gather:before {
  content: "";
}

.codicon-table:before {
  content: "";
}

.codicon-variable-group:before {
  content: "";
}

.codicon-type-hierarchy:before {
  content: "";
}

.codicon-type-hierarchy-sub:before {
  content: "";
}

.codicon-type-hierarchy-super:before {
  content: "";
}

.codicon-git-pull-request-create:before {
  content: "";
}

.codicon-run-above:before {
  content: "";
}

.codicon-run-below:before {
  content: "";
}

.codicon-notebook-template:before {
  content: "";
}

.codicon-debug-rerun:before {
  content: "";
}

.codicon-workspace-trusted:before {
  content: "";
}

.codicon-workspace-untrusted:before {
  content: "";
}

.codicon-workspace-unknown:before {
  content: "";
}

.codicon-terminal-cmd:before {
  content: "";
}

.codicon-terminal-debian:before {
  content: "";
}

.codicon-terminal-linux:before {
  content: "";
}

.codicon-terminal-powershell:before {
  content: "";
}

.codicon-terminal-tmux:before {
  content: "";
}

.codicon-terminal-ubuntu:before {
  content: "";
}

.codicon-terminal-bash:before {
  content: "";
}

.codicon-arrow-swap:before {
  content: "";
}

.codicon-copy:before {
  content: "";
}

.codicon-person-add:before {
  content: "";
}

.codicon-filter-filled:before {
  content: "";
}

.codicon-wand:before {
  content: "";
}

.codicon-debug-line-by-line:before {
  content: "";
}

.codicon-inspect:before {
  content: "";
}

.codicon-layers:before {
  content: "";
}

.codicon-layers-dot:before {
  content: "";
}

.codicon-layers-active:before {
  content: "";
}

.codicon-compass:before {
  content: "";
}

.codicon-compass-dot:before {
  content: "";
}

.codicon-compass-active:before {
  content: "";
}

.codicon-azure:before {
  content: "";
}

.codicon-issue-draft:before {
  content: "";
}

.codicon-git-pull-request-closed:before {
  content: "";
}

.codicon-git-pull-request-draft:before {
  content: "";
}

.codicon-debug-all:before {
  content: "";
}

.codicon-debug-coverage:before {
  content: "";
}

.codicon-run-errors:before {
  content: "";
}

.codicon-folder-library:before {
  content: "";
}

.codicon-debug-continue-small:before {
  content: "";
}

.codicon-beaker-stop:before {
  content: "";
}

.codicon-graph-line:before {
  content: "";
}

.codicon-graph-scatter:before {
  content: "";
}

.codicon-pie-chart:before {
  content: "";
}

.codicon-bracket:before {
  content: "";
}

.codicon-bracket-dot:before {
  content: "";
}

.codicon-bracket-error:before {
  content: "";
}

.codicon-lock-small:before {
  content: "";
}

.codicon-azure-devops:before {
  content: "";
}

.codicon-verified-filled:before {
  content: "";
}

.codicon-newline:before {
  content: "";
}

.primary {
  --r: 88;
  --g: 101;
  --b: 242;
  --color: rgb(var(--r), var(--g), var(--b));
}

.success {
  --r: 59;
  --g: 165;
  --b: 93;
  --color: rgb(var(--r), var(--g), var(--b));
}

.danger {
  --r: 237;
  --g: 66;
  --b: 69;
  --color: rgb(var(--r), var(--g), var(--b));
}

.warning {
  --r: 250;
  --g: 168;
  --b: 26;
  --color: rgb(var(--r), var(--g), var(--b));
}

.info {
  --r: 79;
  --g: 84;
  --b: 92;
  --color: rgb(var(--r), var(--g), var(--b));
}

* {
  margin: 0;
  padding: 0;
}

body {
  color: #dcddde;
  min-height: 100vh;
  background: #0f0e17;
  font-family: Roboto, sans-serif;
  display: grid;
}

body > section {
  justify-content: center;
  margin: 30px 0;
  display: flex;
}

body > section > div.container {
  max-width: 1200px;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  margin: 20px;
  position: relative;
}

body > section > div.container > div.nav {
  justify-content: center;
  margin: 20px;
  display: flex;
}

body > section > div.container > div.debugger {
  max-width: 800px;
  margin: 30px auto;
  display: grid;
}

body > section > div.container > div.debugger > .cells-wrapper {
  max-width: 800px;
  overflow: hidden;
}

body > section > div.container > div.debugger > .cells-wrapper > div.cells {
  display: inline-flex;
  position: relative;
}

body > section > div.container > div.debugger > .cells-wrapper > div.cells > div.cell {
  aspect-ratio: 1 / 1;
  text-align: center;
  width: 50px;
  border: 2px solid #fff;
  margin: 30px 0;
  font-size: 40px;
  font-weight: bolder;
  position: relative;
}

body > section > div.container > div.debugger > .cells-wrapper > div.cells > div.cell > span {
  color: gray;
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  line-height: initial;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
}

body > section > div.container > div.debugger > .cells-wrapper > div.cells > div.cell > p {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - .3em));
}

body > section > div.container > div.debugger > .cells-wrapper > div.cells > div.cell > small {
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  line-height: initial;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -3px);
}

body > section > div.container > div.debugger > .cells-wrapper > div.cells > i.pointer {
  position: absolute;
  top: 50%;
  transform: translate(calc(26px - 50%), 30px);
}

body > section > div.container > div.debugger > div.controls {
  justify-content: center;
  display: flex;
}

body > section > div.container > div.input {
  max-width: 800px;
  margin: 0 auto;
  display: grid;
}

body > section > div.container > div.input:before {
  content: "Input";
  margin: 10px;
}

body > section > div.container > div.input > div.textarea {
  height: auto;
  min-height: 24px;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  max-width: 760px;
  background-color: #e3e3e30a;
  border-radius: 8px;
  margin: 0 auto;
  padding: 11px 20px;
  line-height: 1.5em;
  display: block;
}

body > section > div.container > div.input > div.textarea span[operator] {
  letter-spacing: 2px;
  border: 2px solid #0000;
  padding: 0 1px;
}

body > section > div.container > div.input > div.textarea span[operator][operator="+"] {
  --r: 59;
  --g: 165;
  --b: 93;
}

body > section > div.container > div.input > div.textarea span[operator][operator="-"] {
  --r: 237;
  --g: 64;
  --b: 67;
}

body > section > div.container > div.input > div.textarea span[operator][operator=">"], body > section > div.container > div.input > div.textarea span[operator][operator="<"] {
  --r: 254;
  --g: 215;
  --b: 72;
}

body > section > div.container > div.input > div.textarea span[operator][operator="["], body > section > div.container > div.input > div.textarea span[operator][operator="]"] {
  --r: 250;
  --g: 168;
  --b: 26;
}

body > section > div.container > div.input > div.textarea span[operator][operator="."] {
  --r: 88;
  --g: 101;
  --b: 242;
}

body > section > div.container > div.input > div.textarea span[operator][operator=","] {
  --r: 134;
  --g: 220;
  --b: 197;
}

body > section > div.container > div.input > div.textarea span[operator] {
  background-color: rgba(var(--r), var(--g), var(--b), .3);
}

body > section > div.container > div.input > div.textarea span[operator]:hover, body > section > div.container > div.input > div.textarea span[operator][emphasis] {
  border: 2px solid rgba(var(--r), var(--g), var(--b), .7);
}

body > section > div.container > div.input > div.controls {
  justify-content: right;
  gap: 10px;
  padding: 10px 20px;
  display: flex;
}

body > section > div.container > div.output {
  max-width: 800px;
  margin: 0 auto;
  display: grid;
}

body > section > div.container > div.output:before {
  content: "Output";
  margin: 10px;
}

body > section > div.container > div.output > div.textarea {
  height: auto;
  min-height: 24px;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  max-width: 760px;
  background-color: #e3e3e30a;
  border-radius: 8px;
  margin: 0 auto;
  padding: 11px 20px;
  line-height: 1.5em;
  display: block;
}

body > section > div.container > div.output > div.textarea:before {
  content: ">";
  margin-right: .5em;
}

body > .tooltip {
  color: #0f0e17;
  max-width: 350px;
  background: #cacacc;
  border-radius: 3px;
  padding: 8px;
  position: absolute;
  transform: translate(-50%, calc(-100% - 1.5em));
}

body > .tooltip:after {
  content: " ";
  height: 10px;
  width: 10px;
  z-index: -1;
  background: #cacacc;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%)rotate(45deg);
}

body > footer {
  place-items: center;
  margin-top: auto;
  display: grid;
}

body > footer > a {
  color: #a4a4a4;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  display: grid;
  text-decoration: none !important;
}

body > footer > a:hover {
  color: #fff;
}

button, a {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  min-width: 60px;
  min-height: 32px;
  width: auto;
  color: #fff;
  background-color: #0000;
  border: none;
  border-radius: 8px;
  justify-content: center;
  gap: .5em;
  padding: 11px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  transition: background-color .17s, color .17s;
  display: inline-flex;
}

button:is(a), a:is(a) {
  cursor: default;
  font-weight: normal;
  line-height: 32px;
}

button:is(a)[href], a:is(a)[href] {
  cursor: pointer;
  text-decoration: none;
}

button.block, a.block {
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  display: block;
}

button:hover:not(:disabled), a:hover:not(:disabled) {
  text-decoration: underline;
}

button.outlined, a.outlined {
  background-color: #0000;
  border: 1px solid #ffffff4d;
}

button.outlined:hover, a.outlined:hover {
  background-color: #0000;
  border: 1px solid #fff9;
  text-decoration: none;
}

button.outlined:active, a.outlined:active {
  background-color: #ffffff1a;
  border-color: #fff;
}

button:is(.primary, .success, .danger, .warning, .info), a:is(.primary, .success, .danger, .warning, .info) {
  color: #fff;
  background-color: var(--color);
}

button:is(.primary, .success, .danger, .warning, .info):hover:not(:disabled), a:is(.primary, .success, .danger, .warning, .info):hover:not(:disabled) {
  background-color: rgba(var(--r), var(--g), var(--b), .85);
  text-decoration: none;
}

button:is(.primary, .success, .danger, .warning, .info):active, a:is(.primary, .success, .danger, .warning, .info):active {
  background-color: rgba(var(--r), var(--g), var(--b), .7);
}

button:is(.primary, .success, .danger, .warning, .info).outlined, a:is(.primary, .success, .danger, .warning, .info).outlined {
  color: var(--color);
  border: 1px solid rgba(var(--r), var(--g), var(--b), .3);
  background-color: #0000;
}

button:is(.primary, .success, .danger, .warning, .info).outlined:hover, a:is(.primary, .success, .danger, .warning, .info).outlined:hover {
  border-color: rgba(var(--r), var(--g), var(--b), .3);
  color: #fff;
}

button:is(.primary, .success, .danger, .warning, .info).outlined:active, a:is(.primary, .success, .danger, .warning, .info).outlined:active {
  background-color: rgba(var(--r), var(--g), var(--b), .1);
  border-color: var(--color);
}

button:disabled, a:disabled {
  opacity: .5;
  cursor: not-allowed;
}

textarea {
  width: 400px;
  height: 75px;
  resize: none;
  color: #dcddde;
  background-color: #e3e3e30a;
  border: none;
  border-bottom: 2px solid #0000;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 11px 20px;
  transition: border-bottom-color .1s ease-in-out;
  display: block;
}

textarea.error {
  border-bottom-color: #ed4245cc;
}

textarea::placeholder {
  color: #4f5660;
}

textarea:focus {
  outline: none;
}

x-modal > div.container, client-editor-modal > div.container {
  max-width: 1200px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #0f0e17;
  border-radius: 12px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

x-modal > div.container > header, client-editor-modal > div.container > header {
  justify-content: right;
  margin-bottom: 20px;
  display: grid;
}

x-modal > div.container > main, client-editor-modal > div.container > main {
  padding: 20px 10px;
}

x-modal > div.container > main h3:first-of-type, client-editor-modal > div.container > main h3:first-of-type {
  margin-bottom: .5em;
}

x-modal > div.container > footer, client-editor-modal > div.container > footer {
  grid-gap: 8px;
  border-top: 1px solid #0000000f;
  grid-auto-flow: column;
  justify-content: right;
  margin-top: 20px;
  padding-top: 20px;
  display: grid;
}

x-modal > i, client-editor-modal > i {
  color: #fff;
  cursor: pointer;
  margin: 20px;
  font-size: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

i.bf-instruction {
  color: #f6f6f7;
  cursor: default;
  min-width: 14px;
  min-height: 14px;
  text-align: center;
  box-sizing: border-box;
  height: 23px;
  text-transform: uppercase;
  vertical-align: baseline;
  background-color: #4f545c;
  border: 1px solid #36393f;
  border-radius: 4px;
  margin-right: 3px;
  padding: 3px 6px 4px;
  font-family: Whitney, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  display: inline-block;
  position: relative;
  box-shadow: inset 0 -4px #202225;
}

div.ascii-table {
  grid-column-gap: 10px;
  grid-template-rows: repeat(24, auto);
  grid-auto-flow: column;
  display: grid;
}

div.ascii-table > div {
  grid-gap: .5em;
  border-radius: 3px;
  grid-auto-flow: column;
  justify-content: space-around;
  padding: 5px 8px;
  display: grid;
}

div.ascii-table > div:hover {
  background: #6262625c;
}

div.ascii-table > div > span {
  color: gray;
}

div.ascii-table > div > span:last-of-type {
  color: #fff;
  font-weight: bold;
}

/*# sourceMappingURL=index.fe27a516.css.map */
